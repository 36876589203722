/*-----------------------------------------------
|   Fancy Tab
-----------------------------------------------*/
@keyframes fadeInLeft{
  0%{
    opacity: 0;
    transform: translateX(-3.125rem);
  }
  100%{
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight{
  0%{
    opacity: 1;
    transform: translateX(0);
  }
  100%{
    opacity: 0;
    transform: translateX(3.125rem);
  }
}


@mixin navigation-bar(){
  .fancy-bar{
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-flow: row wrap;
    border-bottom: ($fancy-tab-border-width * 2) solid $fancy-tab-border-color;
    width: 100%;

    .tab-indicator{
      content: '';
      position: absolute;
      display: block;
      bottom: -0.125rem;
      height: 0.125rem;
      background-color: $fancy-tab-active-color;
      transition: left 0.4s ease-in-out, right 0.2s ease-in-out;
      &.transition-reverse{ transition: left 0.2s ease-in-out, right 0.4s ease-in-out; }
    }

    .fancy-bar-item{
      color: $fancy-bar-item-color;
      padding:8px 16px;
      font-size: $fancy-bar-font-size;
      line-height: $fancy-bar-font-size;
      display: inline-block;
      font-weight: $fancy-bar-font-weight;
      margin-bottom: 0;
      text-align: center;
      cursor: pointer;
      border: $fancy-tab-border-width solid transparent;
      &.active{ color: $fancy-tab-active-color; }
    }
  }
}

.fancy-tab{
  display: flex;
  flex-direction: column;

  @include navigation-bar();

  .fancy-tab-contents{
    margin-top: 1.875rem;
    position: relative;

    .fancy-tab-content{
      backface-visibility: hidden;

      &:not(.active){
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 1;
        animation: fadeOutRight .25s ease forwards;
      }

      &.active{ animation: fadeInLeft .5s ease forwards; }
    }
  }
}
